html,
body {
  margin: 0;
  height: 100%;
}

#root,
#root main {
  height: inherit;
}
