@media (max-width: 900px) {
    .userway_buttons_wrapper {
        bottom: 80px !important;
        left: 2px !important;
    }
}

#worthy-zendesk-launcher {
    display: none !important;
}
